@font-face {
	font-family: 'Proxima Nova';
	src: url('fonts/Proxima-Nova-Regular.eot');
	src: url('fonts/Proxima-Nova-Regular.woff2') format('woff2'),
		url('fonts/Proxima-Nova-Regular.woff') format('woff'),
		url('fonts/Proxima-Nova-Regular.ttf') format('truetype'),
		url('fonts/Proxima-Nova-Regular.svg#Proxima-Nova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('fonts/Proxima-Nova-Bold.eot');
	src: url('fonts/Proxima-Nova-Bold.woff2') format('woff2'),
		url('fonts/Proxima-Nova-Bold.woff') format('woff'),
		url('fonts/Proxima-Nova-Bold.ttf') format('truetype'),
		url('fonts/Proxima-Nova-Bold.svg#Proxima-Nova-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('fonts/Proxima-Nova-Light.eot');
	src: url('fonts/Proxima-Nova-Light.woff2') format('woff2'),
		url('fonts/Proxima-Nova-Light.woff') format('woff'),
		url('fonts/Proxima-Nova-Light.ttf') format('truetype'),
		url('fonts/Proxima-Nova-Light.svg#Proxima-Nova-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('fonts/Proxima-Nova-Black.eot');
	src: url('fonts/Proxima-Nova-Black.woff2') format('woff2'),
		url('fonts/Proxima-Nova-Black.woff') format('woff'),
		url('fonts/Proxima-Nova-Black.ttf') format('truetype'),
		url('fonts/Proxima-Nova-Black.svg#Proxima-Nova-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}
