@import './colors';

mat-accordion.flat-expansion-panel .mat-expansion-panel {
	background-color: transparent;
	box-shadow: none;

	.mat-expansion-panel-header {
		padding: 0 16px;
		font-weight: $g-font-weight-bold;
		min-height: 48px;
		line-height: 16px;

		.mat-expansion-indicator {
			margin-left: 5px; // Buffer around the indicator icon
		}
	}

	.mat-expansion-panel-content {
		.mat-expansion-panel-body {
			padding: 0 16px;
		}
	}
}
