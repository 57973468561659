@media print {
	g-preview-banner,
	g-vignette-header,
	g-header,
	g-mobile-navigation {
		display: none !important;
	}

	.page-content {
		g-custom-html-block,
		g-footer {
			display: none !important;
		}
	}
}
