@import './variables';

$g-max-width-product-editor: 880px;
$g-max-width-sm: 500px;
$g-max-width-lg: 700px;
$g-max-header-height: 52px;

.modal {
	z-index: 10001 !important;
}

.modal-backdrop {
	z-index: 10000 !important;
}

.modal-content {
	position: relative;
	max-height: 90vh;
}

.modal-header {
	max-height: $g-max-header-height;
}

.modal-body {
	max-height: calc(90vh - #{$g-max-header-height});
}

.modal-footer {
	background: $g-color-modal-footer-background;
}

.modal-transparent .modal-content {
	background: transparent;
	border: 0;
}

.modal-dialog {
	margin: 5vh auto !important;
	max-width: $g-max-width-lg;
	max-width: var(--g-max-width-modal-dialog, $g-max-width-lg);

	&.modal-sm {
		max-width: $g-max-width-sm;
		max-width: var(--g-max-width-modal-small-dialog, $g-max-width-sm);
	}

	@media screen and (max-width: ($g-breakpoint-sm - 67px)) {
		max-width: 100%;
		margin: 5vh 0 !important;
	}
}

.close-dialog {
	cursor: pointer;
}
