/* stylelint-disable selector-class-pattern -- angular material selectors */
@import './colors';

// Make sure the caret icon spacing is good by default.
.mat-mdc-menu-trigger span + g-icon:last-of-type {
	margin-left: 4px;
}

// Overrides for the main navigation menu styling.
.mat-mdc-menu-panel.main-navigation-menu {
	background-color: $g-color-background-dark;
	max-width: none;
	border-top-left-radius: 0;
	max-height: calc(95dvh - 130px);
	// This prevents unnecessary scrollbars on Safari.
	padding: 2px;

	.mat-mdc-menu-item {
		min-height: 32px;
	}

	&.mega {
		position: relative;
		// On small screens make it full width.
		left: 0;
		width: 100dvw;
		// On large screen align it with the top navigation.
		@media screen and (min-width: ($g-breakpoint-lg + 161px)) {
			left: -180px;
			width: auto;
		}
		// There is this small threshold where it gets wonky, so we need to adjust it.
		// Not exactly sure why, maybe some paddings/margins in the navigation component.
		@media screen and (min-width: $g-breakpoint-lg) and (max-width: ($g-breakpoint-lg + 160px)) {
			left: -50px;
			width: auto;
		}
	}
}

// Overrides for the mobile category picker menu styling.
.mat-mdc-menu-panel.mobile-category-picker-menu {
	width: 100dvw;
	max-width: none;
	max-height: 80dvh;
}

.mat-mdc-menu-trigger[disabled] {
	opacity: 0.6;

	// Hide caret icon on disabled menu triggers.
	span + g-icon:last-of-type {
		display: none;
	}
}

.mat-mdc-autocomplete-panel.product-search-menu {
	max-height: 80dvh;

	.mdc-list-item__primary-text {
		width: 100%;
	}
}
