@import './mixins';
@import './colors';

// TODO: Refactor. This was removed in bootstrap 5.2.
// "Breaking: Dropped form-specific layout classes for our grid system. Use our grid and utilities instead of .form-group, .form-row, or .form-inline."

.form-group {
	@include validation-error;

	.label-no-transform {
		margin-bottom: 6px;

		label {
			color: $g-color-typography;
			font-size: 1.5rem;
			font-weight: 400;
			text-transform: none;
		}
	}

	.checkbox {
		width: auto;
		margin: 0 8px 0 0;
		background: none;

		+ label {
			margin-bottom: 0;
		}
	}

	label {
		font-size: 1.3rem;
		font-weight: bold;
		text-transform: uppercase;
	}

	input,
	textarea {
		border-color: transparent;
		font-size: 1.4rem;
		padding: 10px 16px;
	}

	button {
		font-size: 1.5rem;
		margin: 6px 0 12px;
		text-transform: none;
		width: 100%;
	}
}

.form-group-primary {
	input {
		background-color: $g-color-background-light;
	}
}

.form-group-inverted {
	input {
		background-color: $g-color-background;
	}
}
