@import './colors';

.form-control {
	font-family: 'Proxima Nova', sans-serif;
	font-size: 1.6rem;
	border-radius: 0;
	height: inherit;
	transition: box-shadow 0.15s ease-in-out;

	&:focus {
		border-color: $g-color-border-focus;
		box-shadow: none !important;
	}

	&.checkbox:focus {
		outline: 1px solid $g-color-border-focus;
	}
}

.form-control-primary {
	&:focus {
		background-color: $g-color-background-light;
	}
}

.form-control-inverted {
	&:focus {
		background-color: $g-color-background;
	}
}
