/* stylelint-disable color-no-hex -- duh */
$g-color-black: #111;
$g-color-white: #fff;
$g-color-off-white: #fbfbfb;
$g-color-pink: rgb(241 114 158);

$g-color-primary: #ffcc31;
$g-color-primary-lighter: rgb(255 204 49 / 50%);
$g-color-primary-darker: darken($g-color-primary, 8%);
$g-color-secondary: #424242;
$g-color-success: #1b5e20;
$g-color-danger: #b3261e;
$g-color-error-bg: #ffa09e;
$g-color-warning: #ffebad;
$g-color-warning-dark: darken($g-color-warning, 40%);
$g-color-alert-body: #f9dedc;
$g-color-typography: #3d3d3d;
$g-color-typography-semi-light: lighten($g-color-typography, 20%);
$g-color-typography-light: rgba(0 0 0 / 45%);
$g-color-info-box: #ffebad;
$g-color-delivery-circle: #60daf0;
$g-color-support-circle: #70db99;
$g-color-button-disabled-background: #a6a6a6;

$g-color-background: #fff;
$g-color-background-light: #f2f2f2;
$g-color-background-semilight: #f0f0f0;
$g-color-background-semidark: #f5f5f5;
$g-color-background-dark: #292f32;
$g-color-background-information: #ffebad;
$g-color-background-details: #2a2f33;
$g-color-background-disabled: #d4d4d4;
$g-color-separator-line: #e2e2e2;
$g-color-separator-line-dark: #b2b2b2;
$g-color-input-border-color: rgba(0 0 0 / 17%);

$g-color-border-focus: #ccc;

$g-font-weight-bold: 600;
$g-font-weight-normal: 400;

$g-color-toast-success-bg: $g-color-success;
$g-color-toast-success-text: $g-color-white;
$g-color-toast-error-bg: $g-color-danger;
$g-color-toast-error-text: $g-color-white;
$g-color-toast-warning-bg: $g-color-warning;
$g-color-toast-warning-text: $g-color-black;
$g-color-toast-info-bg: $g-color-secondary;
$g-color-toast-info-text: $g-color-white;

$g-color-redirect-spinner: #d8d8d8;
$g-color-file-icon: #d8d8d8;
$g-color-product-placeholder: #d3d2d3;
$g-color-spinner-light: #fff;
$g-color-spinner-dark: #333;
$g-color-pagination-button-gradient-dark: #eee;
$g-color-paginator-button: #fff;
$g-color-soft-shadow: rgb(0 0 0 / 30%);

$g-color-address-list-border: #d6d6d6;
$g-color-address-list-item-background-odd: #f5f5f5;
$g-color-address-list-item-background-selected: #ebf3ff;
$g-color-address-list-item-border-selected: #236bd7;
$g-color-address-list-card-background: #fbfbfb;
$g-color-address-list-card-border: #ebebeb;
$g-color-address-list-label: #ebebeb;
$g-color-address-list-label-shipping: #ffe6bf;
$g-color-address-list-link: #215ab0;
$g-color-group-list-description: #8c8c8c;

$g-color-modal-footer-background: $g-color-background-semidark;

$g-color-product-editor-button: #30c2ff;

$g-color-approval-status: (
	'pending': #fff4d9,
	'rejected': #ffe6e3,
	'approved': #ddfee5,
	'cancelled': #ffe6e3,
);

$g-color-approval-status-text: (
	'pending': #bf8f26,
	'rejected': #ea6959,
	'approved': #65cd7d,
	'cancelled': #ea6959,
);

@function encode-color($string) {
	@if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
		/* stylelint-disable-next-line function-no-unknown -- sass magic */
		$hex: str-slice(ie-hex-str($string), 4);
		$string: unquote('#{$hex}');

		@return '%23' + $string;
	}

	@return $string;
}
