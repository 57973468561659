.mat-mdc-radio-button {
	.mdc-form-field {
		align-items: start;

		> label {
			padding: 6px 0 0 11px;
		}
	}

	mat-radio-button {
		margin: 19px 0 0;
	}
}
