/* stylelint-disable declaration-no-important -- base overrides */
@import './colors';
@import './variables';

html {
	// Make it so that 1.6rem == 16px, 2rem == 20px etc.
	/* stylelint-disable-next-line declaration-property-unit-allowed-list -- reset */
	font-size: 62.5%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $g-color-typography;
}

// Default colors.
:root {
	// Sass requires using interpolation when used in CSS variables
	--g-color-primary: #{$g-color-primary};
	--g-color-primary-text: #{$g-color-black};
	--g-color-primary-highlight: #{$g-color-primary-darker};
	--g-color-primary-lighter: #{$g-color-primary-lighter};
	--g-color-primary-focus: #{$g-color-primary-darker};
	--g-color-info-box: #{$g-color-info-box};
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0 0 0.8rem;
	font-weight: 900;
	line-height: 1.2;
}

input {
	line-height: 1.15;
}

a {
	color: $g-color-black;
	text-decoration: underline;

	&:hover {
		color: $g-color-black;
	}
}

.async-hide {
	opacity: 0 !important;
}

@media screen and (max-width: $g-breakpoint-md) {
	.page-content {
		padding-top: 64px;
	}
}

@media print {
	body {
		min-width: none !important;
	}
}

[hidden] {
	display: none !important;
}

// TODO: Remove.
.d-inline-block {
	display: inline-block;
}

// TODO: Remove, needed by Bootstrap modals.
.d-block {
	display: block !important;
}

.only-logged-in,
.only-logged-out {
	display: none !important;
}

body.is-user-logged-in .only-logged-in {
	display: block !important;
}

body:not(.is-user-logged-in) .only-logged-out {
	display: block !important;
}
