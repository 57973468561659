/* stylelint-disable custom-property-pattern -- BS */
@import './colors';
@import './variables';

$enable-dark-mode: false;

// Colors

$body-bg: $g-color-background;

$brand-primary: $g-color-primary;
$brand-secondary: $g-color-secondary;
$brand-success: $g-color-success;
// $brand-info:      $teal !default;
// $brand-warning:   $orange !default;
$brand-danger: $g-color-danger;
// $brand-inverse:   $gray-dark !default;

// Fonts

$font-family-sans-serif: 'Proxima Nova', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1.6rem;
$font-size-lg: 2rem;
$font-size-sm: 1.4rem;
$font-size-xs: 1.3rem;

$line-height-base: 1.5;
$input-btn-line-height: 1.25;

// Buttons

$btn-padding-x: 16px;
$btn-padding-y: 0;
$btn-line-height: 50px;
$btn-font-weight: bold;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$btn-primary-color: $g-color-black;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;

$btn-secondary-color: $g-color-white;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: transparent;

// $btn-info-color: $white !default;
// $btn-info-bg: $brand-info !default;
// $btn-info-border: transparent;

$btn-success-color: $g-color-typography;
$btn-success-bg: $brand-success;
$btn-success-border: transparent;

// $btn-warning-color: $white !default;
// $btn-warning-bg: $brand-warning !default;
// $btn-warning-border: transparent;

$btn-danger-color: $g-color-typography;
$btn-danger-bg: $brand-danger;
$btn-danger-border: transparent;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$btn-padding-x-sm: $btn-padding-x;
$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;
$btn-padding-y-lg: $btn-padding-y;

// Forms

$input-line-height: 1.15;

// Was the default in Bootstrap 4
$form-check-padding-start: 1.25rem;
// $form-check-min-height: 2.1rem;
// $form-check-margin-bottom: 0;

// Grid

$grid-gutter-width: 30px;
$grid-breakpoints: (
	xs: 0,
	sm: $g-breakpoint-sm,
	md: $g-breakpoint-md,
	lg: $g-breakpoint-lg,
	xl: $g-breakpoint-xl,
);
$container-max-widths: (
	xs: $g-container-max-width-xs,
	sm: $g-container-max-width-sm,
	md: $g-container-max-width-md,
	lg: $g-container-max-width-lg,
	xl: $g-container-max-width-xl,
);

// Bootstrap 5 root variables.
:root {
	--bs-body-font-family: #{$font-family-base};
	--bs-body-font-size: #{$font-size-base};
	--bs-body-line-height: #{$line-height-base};
	--bs-body-bg: #{$body-bg};
	// "left" was the default in bootstrap 4
	--bs-body-text-align: left;
	--bs-border-width: 1px;
	--bs-border-color-translucent: #{$g-color-separator-line};
	--bs-tertiary-bg: #{$g-color-background-semidark};
}
