@import './colors';

.cdk-overlay-container {
	// Lift overlay container on top of modals
	z-index: 10100;
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
	// Temporary(?) width increase for transitioning from angular2-toaster to snackbar.
	// SnackBars are 33vw by default and toasts are more like 50vw.
	max-width: 50vw;

	&.is-clickable {
		cursor: pointer;
		pointer-events: all;
	}

	&.toast-success {
		--mdc-snackbar-container-color: #{$g-color-toast-success-bg};
		--mdc-snackbar-supporting-text-color: #{$g-color-toast-success-text};
	}

	&.toast-error {
		--mdc-snackbar-container-color: #{$g-color-toast-error-bg};
		--mdc-snackbar-supporting-text-color: #{$g-color-toast-error-text};
	}

	&.toast-warning {
		--mdc-snackbar-container-color: #{$g-color-toast-warning-bg};
		--mdc-snackbar-supporting-text-color: #{$g-color-toast-warning-text};
	}

	&.toast-info {
		--mdc-snackbar-container-color: #{$g-color-toast-info-bg};
		--mdc-snackbar-supporting-text-color: #{$g-color-toast-info-text};
	}
}
