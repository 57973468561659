@import './mixins';
@import './colors';

.container {
	@extend %container;
}

.background {
	background: $g-color-background;
}
