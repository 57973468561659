/* stylelint-disable annotation-no-unknown, at-rule-no-unknown -- scss features */
@use 'sass:math';

@import './config';
@import './colors';
@import './variables';
@import 'bootstrap/scss/mixins/breakpoints';

/* extra imports to make the deprecated make-container-max-widths mixin work */
@import './ng-bootstrap/bootstrap-base';
@import 'bootstrap/scss/mixins/deprecate';

/* end extra imports */
@import 'bootstrap/scss/mixins/grid';

@mixin make-container($padding-x: $container-padding-x) {
	width: 100%;
	padding-right: $padding-x;
	padding-left: $padding-x;
	margin-right: auto;
	margin-left: auto;
}

@mixin g-make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

%container {
	@include make-container;
	@include g-make-container-max-widths;
	padding: 0 $g-padding-container;
	margin: 0 auto;
}

$resolution-default: 2 !default;
// spell-checker:disable
$resolution-presets: (
	'ldpi' 75%,
	'mdpi' 100%,
	'hdpi' 150%,
	'xhdpi' 200%,
	'xxhdpi' 300%,
	'xxxhdpi' 400%,
	'retina' 2,
	'@2x' 2,
	'@3x' 3
) !default;
// spell-checker:enable

@function resolution-preset($resolution) {
	@if type-of($resolution) == 'string' {
		@each $preset in $resolution-presets {
			@if nth($preset, 1) == $resolution {
				@return nth($preset, 2);
			}
		}
	}
	@return $resolution;
}

@mixin resolution($resolution: $resolution-default) {
	/* stylelint-disable-next-line function-no-unknown -- it is known */
	$resolution: resolution-preset($resolution);
	@if type-of($resolution) == 'number' {
		@if unit($resolution) == 'dpi' {
			$resolution: math.div($resolution, 96dpi);
		} @else if unit($resolution) == '%' {
			$resolution: math.div($resolution, 100%);
		} @else {
			$resolution: math.div($resolution, $resolution * 0 + 1);
		}
		/* stylelint-disable-next-line media-feature-name-no-vendor-prefix -- for Safari */
		@media (-webkit-min-device-pixel-ratio: #{$resolution}), (min-resolution: #{$resolution * 96dpi}) {
			@content;
		}
	} @else {
		@error "#{$resolution} is not a valid resolution or preset";
	}
}

@mixin component($contain: content, $display: block) {
	contain: $contain;
	display: $display;
}
@mixin content-page {
	::ng-deep .content-container {
		width: 100%;
		margin: 0 auto 50px;

		.text-page {
			max-width: 550px;
			margin: auto;
			padding: 0 15px;
		}

		h1 {
			font-size: 3rem;
			padding: 0;
			width: 100%;
			max-width: 340px;
			margin: 40px auto 0;
			text-align: center;
			font-weight: 900;
			text-transform: none;
			line-height: 3.5rem;
		}

		h2 {
			font-size: 2.2rem;
			text-transform: none;
			padding: 50px 0 20px;
			font-weight: 600;
		}

		h3 {
			font-size: 1.875rem;
			text-transform: none;
			font-weight: 600;
		}

		.separator {
			width: 100%;
			max-width: 260px;
			margin: 50px auto 40px;
			border-bottom: 1px solid $g-color-typography-light;
		}

		p {
			font-size: 1.6rem;
		}

		ol,
		ul {
			li {
				font-size: 1.6rem;
			}
		}

		.capitalize-headers {
			h1,
			h2 {
				text-transform: capitalize;
			}
		}

		.page-not-found {
			text-align: center;

			h1 {
				margin: 40px auto 20px;
			}
		}
	}
}
@mixin ngx-schema-form {
	/* stylelint-disable-next-line selector-pseudo-element-no-unknown -- required */
	::ng-deep sf-form-object {
		fieldset {
			border: 0;
			margin: 0;
			padding: 0;
		}
	}
}
@mixin validation-error {
	.validation-error {
		font-size: 1.2rem;
		min-height: 16px;
		color: $g-color-danger;
		line-height: 1.4;
	}
}
@mixin custom-color-primary {
	background-color: $g-color-primary;
	background-color: var(--g-color-primary);
	color: $g-color-black;
	color: var(--g-color-primary-text);
}
@mixin form-check {
	.show-terms {
		cursor: pointer;
		text-decoration: underline;
	}

	.form-check-label {
		font-weight: normal;
		font-size: 1.4rem;
		text-transform: none;

		&[disabled] {
			color: $g-color-typography-light;
		}

		.form-check-input {
			[type='checkbox'] {
				font-size: 2rem;
			}

			[type='radio'] {
				font-size: 1.4rem;
			}
		}

		span {
			padding: 0 4px 0 8px;
		}
	}
}

@mixin order-flow {
	font-size: 1.8rem;

	h3 {
		font-size: 3.2rem;
	}

	h4 {
		font-size: 2.4rem;
	}

	h5 {
		font-size: 1.6rem;
	}

	label {
		font-size: 1.4rem;
	}
}

@mixin approval-status-color($status) {
	background-color: map-get($g-color-approval-status, $status);
	color: map-get($g-color-approval-status-text, $status);
}
