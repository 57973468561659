@use '@angular/material' as mat;
@import './colors';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$storefront-typography: mat.m2-define-typography-config(
	$font-family: '"Proxima Nova", sans-serif',
);
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// TODO: Using grey until ED-2194 is resolved.
$storefront-primary: mat.m2-define-palette(mat.$m2-grey-palette);
$storefront-accent: mat.m2-define-palette(mat.$m2-grey-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$storefront-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $storefront-primary,
			accent: $storefront-accent,
		),
		typography: $storefront-typography,
		density: -3,
	)
);

// Include theme styles for core and each component used in your app.
@include mat.core-theme($storefront-theme);
@include mat.progress-bar-theme($storefront-theme);
@include mat.fab-theme($storefront-theme);
@include mat.snack-bar-theme($storefront-theme);
@include mat.button-theme($storefront-theme);
@include mat.icon-button-theme($storefront-theme);
@include mat.menu-theme($storefront-theme);
@include mat.form-field-theme($storefront-theme);
@include mat.input-theme($storefront-theme);
@include mat.select-theme($storefront-theme);
@include mat.option-theme($storefront-theme);
@include mat.slide-toggle-theme($storefront-theme);
@include mat.dialog-theme($storefront-theme);
@include mat.expansion-theme($storefront-theme);
@include mat.radio-theme($storefront-theme);
@include mat.datepicker-theme($storefront-theme);
@include mat.card-theme($storefront-theme);
@include mat.autocomplete-theme($storefront-theme);
@include mat.tabs-overrides(
	(
		container-height: 50px,
		inactive-label-text-color: $g-color-typography-light,
		active-label-text-color: $g-color-typography,
		inactive-hover-label-text-color: $g-color-typography,
		divider-height: 1px,
		divider-color: $g-color-separator-line,
		label-text-weight: $g-font-weight-bold,
	)
);

// This is set by Material. Doesn't seem to be a strict requirement but might
// prevent future positioning issues with Material components that "should just work".
// It didn't seem to cause any adverse effects to the current layout either.
html,
body {
	--mdc-outlined-text-field-outline-color: #{$g-color-input-border-color};
	height: 100%;
}

// Include customizations.
@import './border-radius';
@import './button';
@import './menu';
@import './snackbar';
@import './form-field';
@import './expansion-panel';
@import './radio';

// TODO: Move this to a separate file to not bloat this file.
.tooltip {
	color: $g-color-white;
	background-color: $g-color-typography;
	font-size: 1.4rem;
}
