/* stylelint-disable selector-class-pattern -- material overrides */
/* stylelint-disable declaration-no-important -- material overrides */
@import './variables';

.mat-mdc-dialog-container .mdc-dialog__surface {
	--mdc-dialog-container-shape: #{$g-border-radius-small} !important;
}

.mdc-text-field--outlined {
	--mdc-outlined-text-field-container-shape: #{$g-border-radius-small} !important;
}

.mat-mdc-select-panel {
	border-bottom-left-radius: $g-border-radius-small !important;
	border-bottom-right-radius: $g-border-radius-small !important;
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
	border-radius: $g-border-radius-small !important;
}

.mat-mdc-menu-panel {
	--mat-menu-container-shape: #{$g-border-radius-small} !important;
}

.mat-expansion-panel {
	--mat-expansion-container-shape: #{$g-border-radius-small};
}
