/* stylelint-disable declaration-no-important -- material overrides */
@use 'sass:math';

// General button tweaks.
.mdc-button {
	// Set letter spacing.
	/* stylelint-disable-next-line number-max-precision -- material default */
	$button-letter-spacing: math.div(0.0892857143em, 3);

	--mdc-text-button-label-text-tracking: #{$button-letter-spacing};
	--mdc-filled-button-label-text-tracking: #{$button-letter-spacing};
	// Set button height.
	--mdc-text-button-container-height: 36px;
	--mdc-filled-button-container-height: 36px;
	--mdc-outlined-button-container-height: 36px;
	// Disable default hover overlay.
	&:hover:not(:disabled) .mat-mdc-button-persistent-ripple::before {
		opacity: 0 !important;
	}

	// Set primary button colors.
	&.mat-primary {
		--mdc-filled-button-label-text-color: var(--g-color-primary-text);
		--mdc-filled-button-container-color: var(--g-color-primary);
	}

	g-icon {
		min-width: 16px;

		&:last-child {
			margin-left: 6px;
		}

		&:first-child {
			margin-right: 6px;
		}
	}
}

.mat-mdc-icon-button {
	// Add more sizes as needed.
	&:has(g-icon[size='sm']) {
		--mdc-icon-button-state-layer-size: 32px;
		--mdc-icon-button-icon-size: 17px;
	}
}

// Set special styles inside a form group.
.form-group-v2 {
	.mat-mdc-unelevated-button,
	.mat-mdc-outlined-button {
		padding: 13px 24px;
		font-size: 1.4rem;
		line-height: 1.4rem;
		font-weight: 600;

		&:disabled {
			background-color: $g-color-address-list-label;
			color: $g-color-separator-line-dark;
		}

		&:not(:disabled) {
			// Used in e.g. billing address step.
			&.btn-dark {
				background-color: $g-color-typography;
				color: $g-color-white;
			}
		}

		g-icon {
			margin-right: 8px;
		}

		@media screen and (min-width: $g-breakpoint-md) {
			width: auto;
			margin: 0;
		}
	}

	.mat-mdc-unelevated-button {
		background-color: $g-color-typography;
		color: $g-color-white;
	}

	.mat-mdc-outlined-button {
		border-color: $g-color-black;

		&:disabled {
			border-color: $g-color-address-list-border;
			background-color: transparent;
		}
	}
}
