@import './colors';

label,
label > * {
	&.is-required {
		&::after {
			content: '*';
			display: inline-block;
			padding: 0 0 0 1px;
			font-size: 150%;
			line-height: 0;
			color: $g-color-danger;
			vertical-align: middle;
		}
	}
}

.form-group-v2 {
	@include validation-error;

	.validation-error {
		font-weight: 700;
		text-transform: uppercase;
	}

	input {
		font-size: 1.4rem;
		padding: 10px 16px;
		border-radius: $g-border-radius-large;
		border: 1px solid $g-color-separator-line-dark;

		&:disabled {
			background: $g-color-white;
			opacity: 0.5;
		}
	}

	label {
		font-weight: 700;
		text-transform: uppercase;
		line-height: 2.4rem;
		font-size: 1.4rem;

		.sub-label {
			display: block;
			color: $g-color-typography-light;
			font-style: normal;
			font-weight: 400;
			text-transform: initial;
		}
	}
}
