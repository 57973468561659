@import './colors';

a.btn {
	line-height: 50px;
}

.btn {
	display: inline-block;
	color: $body-color;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 0;
	font-family: 'Proxima Nova', sans-serif;
	font-size: 1.3rem;
	font-weight: bold;
	min-width: 230px;
	min-height: 50px;
	padding-top: 1px;
	padding-bottom: 0;
	@include transition($btn-transition);

	&:hover {
		color: $body-color;
		text-decoration: none;
	}

	&:focus,
	&.focus {
		outline: 1px solid var(--g-color-primary-focus);
	}

	&:not(:disabled, .disabled):active,
	&:not(:disabled, .disabled).active {
		@include box-shadow($btn-active-box-shadow);

		&:focus {
			@include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
		}
	}

	&:hover:not([disabled]) {
		cursor: pointer;
	}

	&.btn-primary {
		background-color: $g-color-primary;
		background-color: var(--g-color-primary);
		color: $g-color-black;
		color: var(--g-color-primary-text);

		&:hover:enabled {
			background-color: $g-color-primary-darker;
			background-color: var(--g-color-primary-highlight);
			color: $g-color-black;
			color: var(--g-color-primary-text);
		}

		&:not(:disabled, .disabled).active,
		&:not(:disabled, .disabled):active {
			background-color: var(--g-color-primary-highlight);
			color: $g-color-black;
			color: var(--g-color-primary-text);
		}

		&:focus,
		&.focus {
			outline: 1px solid var(--g-color-primary-text);
		}
	}

	&.btn-secondary {
		background-color: $g-color-typography;
		color: $g-color-white;
	}

	&.btn-tertiary {
		color: $g-color-typography;
		text-transform: none;

		&:focus,
		&:hover {
			background-color: lighten($g-color-background-light, 0);
		}
	}

	&.btn-danger {
		background-color: $g-color-danger;
		color: $g-color-white;
	}

	&.btn-inline-link {
		transition: color 0.2s;
		color: $g-color-typography;
		text-transform: none;
		text-decoration: underline;
		min-width: 0;
		min-height: 0;
		padding: 0;
		width: auto;
		font-weight: normal;
		font-size: 1.6rem;
		vertical-align: text-top;

		&:focus,
		&:hover {
			background-color: transparent;
			color: lighten($g-color-typography, 30%);
		}
	}

	&.btn-small {
		min-width: 100px;
		padding: 1px 1.6rem 0;
	}

	g-icon {
		display: inline-block;
		vertical-align: middle;
	}

	&:disabled,
	&.disabled {
		color: $g-color-typography-semi-light;
		background-color: $g-color-button-disabled-background;
	}

	&.btn-rounded {
		border-radius: 2px;
	}
}
