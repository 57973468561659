$g-checkout-form-min-height: 520px;

$g-breakpoint-xs: 599px;
$g-breakpoint-sm: 767px;
$g-breakpoint-md: 959px;
$g-breakpoint-lg: 1259px;
$g-breakpoint-xl: 1260px;

$g-container-max-width-xs: 690px;
$g-container-max-width-sm: 750px;
$g-container-max-width-md: 970px;
$g-container-max-width-lg: 1199px;
$g-container-max-width-xl: 1200px;

$g-padding-dialog: 24px;
$g-padding-container: 15px;

$g-border-radius-small: 2px;
$g-border-radius-large: 3px;
