@import './colors';

.select {
	text-transform: none;
	border: 0.1rem solid $g-color-input-border-color;
	border-radius: 0;
	padding: 0 0.75rem;
	line-height: 36px;
}

.show > .select {
	background-color: $g-color-primary-darker;
	background-color: var(--g-color-primary-highlight);
}
