.square-image-container {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 100%; // Dynamic 1:1 aspect ratio

	.square-image-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		g-zoomable-image {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.square-image-centered {
		object-fit: contain;
		display: block;
		position: relative;
		top: 50%;
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
		transform: translateY(-50%);
	}
}

figure.thumbnail {
	position: relative;
	margin: auto;
	width: 80px;
	height: 80px;
	background-color: $g-color-background-light;
	outline: 1px solid $g-color-background-light;

	img {
		object-fit: contain;
		// TODO: Remove these when NgOptimizedImage is used everywhere.
		position: absolute;
		width: 100%;
		height: 100%;
	}
}
