.g-icon-layers {
	display: inline-block;
	width: 1em;
	height: 1em;
	position: relative;
	text-align: center;

	g-icon {
		inset: 0;
		margin: auto;
		position: absolute;

		svg {
			vertical-align: top;
		}
	}
}
